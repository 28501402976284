<template>
  <app-layout>
    <template #header> Adauga retragere </template>

    <vendors-withdrawals-create-form
      class="mb-6"
      :vendor-id="id"
      @withdrawal:created="redirect()"
    ></vendors-withdrawals-create-form>
  </app-layout>
</template>

<script>
import AppLayout from "../layout/AppLayout.vue";
import VendorsWithdrawalsCreateForm from "../components/VendorsWithdrawalsCreateForm.vue";

export default {
  components: {
    AppLayout,
    VendorsWithdrawalsCreateForm,
  },

  props: ["id"],

  data() {
    return {
      isLoading: false,
    };
  },

  methods: {
    redirect() {
      this.$router.push({
        name: "vendors.withdrawals.index",
        params: { id: this.id },
      });
    },
  },
};
</script>

<style></style>
