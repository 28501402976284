import { VENDORS_WITHDRAWALS, VENDORS_BALANCE, VENDORS_WITHDRAWALS_SHOW } from "../endpoints";
import api from "../api";

export default {
    index(vendor = null, query = null) {
        if (!vendor)
            return;

        const url = VENDORS_WITHDRAWALS.replace(":vendor", vendor);

        if (query !== null) {
            url.search = new URLSearchParams(query).toString();;
        }

        return api.get(url);
    },

    store(data, { vendor }) {
        if (data === null) return;

        const url = VENDORS_WITHDRAWALS.replace(":vendor", vendor);

        if (Object.prototype.hasOwnProperty.call(data, 'amount')) {
            data.amount = data.amount * 100;
        }

        return api.post(url, data);
    },

    destroy(vendor = null, withdrawal = null) {
        if ((vendor === null) || (withdrawal === null)) return

        const url = VENDORS_WITHDRAWALS_SHOW.replace(":vendor", vendor).replace(':withdrawal', withdrawal);

        return api.delete(url);
    },


    balance(vendor) {
        if (vendor === null) return;

        const url = VENDORS_BALANCE.replace(":vendor", vendor);

        return api.get(url);
    }
}