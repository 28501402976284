<template>
  <form
    action=""
    method="POST"
    @submit.prevent="onSubmit"
    @keydown="form.errors.clear($event.target.name)"
    enctype="multipart/form-data"
    class="max-w-3xl mx-auto"
  >
    <v-card class="mb-2">
      <v-card-body>
        <div class="mb-2">
          <v-label>Suma </v-label>
          <v-input type="number" step=".01" v-model="form.amount"></v-input>
          <v-error :error="form.errors.get('amount')"></v-error>
        </div>
      </v-card-body>
      <v-card-footer class="text-right">
        <v-button :disabled="form.loading">
          <v-button-icon v-if="form.loading">
            <loader :radius="5" />
          </v-button-icon>
          Salveaza
        </v-button>
      </v-card-footer>
    </v-card>
  </form>
</template>
<script>
import vendorsWithdrawalsApi from "@/api/resources/vendorsWithdrawalsApi";
import Form from "@/core/Form";
import VButton from "@/components/ui/VButton.vue";
import VCard from "@/components/ui/VCard.vue";
import VInput from "@/components/ui/VInput.vue";
import VLabel from "@/components/ui/VLabel.vue";
import VError from "@/components/ui/VError.vue";
import VCardBody from "@/components/ui/VCardBody.vue";
import VCardFooter from "@/components/ui/VCardFooter.vue";
import VButtonIcon from "@/components/ui/VButtonIcon.vue";
import Loader from "@/components/Loader.vue";

export default {
  components: {
    VCard,
    VLabel,
    VInput,
    VButton,
    VError,
    VCardBody,
    VCardFooter,
    VButtonIcon,
    Loader,
  },

  props: {
    vendorId: { type: String },
  },

  emits: ["withdrawal:created"],

  data() {
    return {
      form: new Form({
        amount: "",
      }),
      loading: false,
    };
  },

  methods: {
    onSubmit() {
      this.loading = true;
      this.form
        .submitService(vendorsWithdrawalsApi, "store", {
          vendor: this.vendorId,
        })
        .then((response) => {
          this.$emit("withdrawal:created", response.data);
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
  created() {
    this.loading = true;
    vendorsWithdrawalsApi
      .balance(this.vendorId)
      .then((response) => {
        this.form.amount = response.data;
        this.loading = false;
      })
      .catch(() => {
        this.loading = false;
      });
  },
};
</script>

<style></style>
