<template>
  <div class="px-4 py-3 bg-gray-50 sm:px-6">
    <slot></slot>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
