<template>
  <span class="block text-red-500 text-sm" v-if="error">
    {{ error }}
  </span>
</template>

<script>
export default {
  props: {
    error: { type: String, default: null },
  },
};
</script>

<style></style>
