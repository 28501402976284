<template>
  <div class="flex justify-center items-center">
    <div
      class="animate-spin rounded-full border-b-2 border-gray-900"
      :class="radiusClass"
    ></div>
  </div>
</template>

<script>
export default {
  props: {
    radius: {
      type: Number,
      default: 32,
    },
  },
  computed: {
    radiusClass() {
      return `h-${this.radius} w-${this.radius}`;
    },
  },
};
</script>

<style></style>
