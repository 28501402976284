<template>
  <i class="-ml-1 mr-2 h-5 w-5" aria-hidden="true">
    <slot></slot>
  </i>
</template>

<script>
export default {};
</script>

<style></style>
